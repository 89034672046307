<script setup lang="ts">
import type { JamBaseIconSize } from './JamBaseIcon.vue';

export type JamBaseLinkVariant = 'dark-gray' | 'gray' | 'green' | 'white';
export type JamBaseLinkStrokeSizeVariant = 'thick' | 'thin';
export type JamBaseLinkAs = 'button' | 'nuxt-link' | 'a' | 'span';

const props = defineProps({
    as: {
        default: 'nuxt-link',
        type: String as PropType<JamBaseLinkAs>,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    flipTrailingIcon: {
        default: false,
        type: Boolean,
    },
    hasTrailingIcon: {
        default: false,
        type: Boolean,
    },
    hideIcon: {
        default: false,
        type: Boolean,
    },
    href: {
        default: null,
        type: String,
    },
    iconName: {
        default: '',
        type: String,
    },
    iconSize: {
        default: 'large',
        type: String as PropType<JamBaseIconSize>,
    },
    strokeSize: {
        default: 'thick',
        type: String as PropType<JamBaseLinkStrokeSizeVariant>,
    },
    target: {
        default: null,
        type: String,
    },
    variant: {
        default: 'green',
        type: String as PropType<JamBaseLinkVariant>,
    },
});

const resolvedComponent = computed(() => {
    if (props.as === 'nuxt-link') {
        return resolveComponent('nuxt-link');
    }

    return props.as;
});

const computedHref = computed(() => {
    if (props.as !== 'a' && props.as !== 'nuxt-link') {
        return undefined;
    }

    if (props.disabled) {
        return undefined;
    }

    return props.href;
});

const computedTrailingIcon = computed(() => props.iconName || 'caret-down');
</script>

<template>
    <Component
        :is="resolvedComponent"
        :class="{
            'cursor-not-allowed text-gray-400': disabled,
            'cursor-pointer': !disabled,
            'flex items-center gap-2': iconName || hasTrailingIcon,
            'font-semibold':
                !iconName &&
                !hasTrailingIcon &&
                !hideIcon &&
                strokeSize !== 'thin',
            underline: !iconName && !hasTrailingIcon && !hideIcon,
            'text-gray-600 hover:text-gray-900':
                !disabled && variant === 'gray',
            'text-gray-900 hover:text-gray-600':
                !disabled && variant === 'dark-gray',
            'text-green-800 hover:text-gray-900':
                !disabled && variant === 'green',
            'text-white hover:opacity-80': !disabled && variant === 'white',
        }"
        class="inline-flex items-center text-base leading-normal"
        :disabled="as === 'button' ? disabled : undefined"
        :data-disabled="disabled"
        :href="computedHref"
        :target="as === 'a' || as === 'nuxt-link' ? target : undefined"
        :type="as === 'button' && 'button'"
    >
        <JamBaseIcon
            v-if="iconName && !hasTrailingIcon"
            :icon-name="iconName"
            :size="iconSize"
        />

        <slot />

        <JamBaseIcon
            v-if="hasTrailingIcon"
            :icon-name="computedTrailingIcon"
            :size="iconSize"
            class="transition-transform"
            :class="[flipTrailingIcon && 'rotate-180']"
        />
    </Component>
</template>
